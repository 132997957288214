@import 'variables';
body{
  .resources-analytic-tracker{
    .analytic-head{
      h2{
        @include elementStyles($size: 24px, $color: #bd10e0, $fontFamily: $ibmplexRegular);
      }
      p{
        @include elementStyles($size: 16px, $color: #4c4c4c, $fontFamily: $ibmplexRegular);
        margin-bottom: 10px;
      }
      ul{
        border-bottom: 1px solid #a1a1a1;
        width: 100%;
        margin-bottom: 20px;
        li{
          cursor: pointer;
          padding: 10px 30px;
          &.active{
            color: #3f51b5;
            border-bottom: 2px solid #3f51b5;
          }
        }
      }
    }
    .analytic-blk{
      ul{
        li{
          margin-right: 15px;
          div{
            padding: 5px 30px;
            background: #fff;
            h4{
              @include elementStyles($size: 24px, $color: #ab3800, $fontFamily: $ibmplexRegular);
            }
            p{
              @include elementStyles($size: 18px, $color: #ab3800, $fontFamily: $ibmplexRegular);
            }
            &.available{
              h4,p{
                color: #00a753;
              }
            }
            &.deployed{
              h4,p{
                color: #ab3800;
              }
            }
          }
        }
      }
    }

  }
  .project-tracker-section{
    .tracker-title{
      margin-bottom: 10px;
      h1{
        @include elementStyles($size: 18px, $color: #bd10e0, $fontFamily: $ibmplexRegular, $margin: 0 0 6px 0);
      }
      p{
        @include elementStyles($size: 13px, $color: #838383, $fontFamily: $ibmplexRegular);
      }
    }
    .tracker-export{
      float: right;
      .utilization-graph{
        color: #3f51b5;
      }
      li{
        margin-left: 10px;
        cursor: pointer;
      }
    }
    table{
      .project-info-col{
        .lessor-info{
          img{
            width: 48px;
            margin-right: 10px;
          }
        }
        .project-details{
          .project-number{
            @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexSemiBold);
          }
          h3{
            @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular);
          }
          .lead-by{
            span{
              color: #000000;
            }
          }
        }
      }
      .user-profile-info{
        h4{
          margin-bottom: 6px;
        }
        .designation, .department{
          @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        .location{
          @include elementStyles($size: 12px, $color: #acacac, $fontFamily: $ibmplexRegular);
        }
        .profile-pic{
          margin-right: 10px;
        }
      }
      svg{
        font-size: 1rem;
        margin-left: 2px;
        cursor: pointer;
      }
    }
    .cor-table{
      .project-info-col{

      }
      .engineer-status{
        h4{
          @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        p{
          @include elementStyles($size: 11px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
      }
      .rating-info{
        table, tr,td{
          background: transparent;
          border: none;
        }
        td{
          padding: 0 !important;
        }
      }
    }
  }
  .cor-hd{
    tr{
      th{
        background-color: #FAFAFA;
      }
    }
  }
  .tracker-block{
    .title-block{
      h1{
        @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
        margin-bottom: 3px;
      }
      p{
        @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
      }
    }
    .project-tracker{
      .project-tracker-wrapper{
        .project-tracker-block{
          flex-flow:nowrap;
          border-right: 1px solid #d7d7d7;
          &.header-sep-block{
            .spacing-cls{
              padding:10px 10px !important;
              border:1px solid #ccc;
              border-left:0;
              &.project-heading-block{
                flex-flow: nowrap;
                background: #fff;
                position:relative;
                z-index:1;
                box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
                -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
                -moz-box-shadow:5px 0 10px -5px rgba(0,0,0,0.4);
                border-left:1px solid #ccc;
                padding: 0 !important;
                .innerheading-block{
                  width: 33.3%;
                  border-right: 1px solid #d7d7d7;
                  justify-content: left;
                  padding: 15px;
                  p{
                    font-size: 16px;
                  }
                }
              }
            }
            .flex-shrink-flow{
              flex-grow: 0;
              flex-shrink: 0;
              display: flex;
              .spacing-cls{
                flex-basis: 300px;
                flex-grow: 0;
                flex-shrink: 0;
                &.border-right{
                  border-right: 1px solid #ccc;
                  &:last-child{
                    border-right: none !important;
                  }
                }
              }
            }
          }
          .inner-width-block{
            overflow: auto;
            &::-webkit-scrollbar{
              display: none;
            }
            // table  scss
            .row-header{
              background: #fff;
              margin:0;
              flex-shrink: 0;
              flex-grow: 0;
              flex-wrap: nowrap;
              .first-block{
                border-top:1px solid #d7d7d7;
                border-bottom:1px solid #d7d7d7;
                padding:4px;
                span{
                  display: block;
                  text-align: center;
                }
              }
              .second-block{
                ul{
                  flex-flow: nowrap;
                  display:flex;
                  border-bottom:1px solid #d7d7d7;
                  li{
                    border-right:1px solid #d7d7d7;
                    width: 40px;
                    margin: 0px;
                    text-align: center;
                    height: 40px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    &:last-child{
                      border-right:none;
                    }
                  }
                }
              }
              span{
                font-family:$ibmplexRegular;
                font-size:14px;
                color:#8c9ba5;
                &.caption-ui{
                  font-size: 12px;
                }
              }
              p{
                font-family:$ibmplexRegular;
                font-size:14px;
                color:#bd10e0;
              }
              a{
                font-family:$ibmplexRegular;
                font-size:12px;
                color:#2f8cff;
                &:hover{
                  color:#2f8cff;
                  &:hover{
                    color:#2f8cff;
                  }
                }
              }
              .item-block{
                border-bottom: 1px solid #ccc;
                border-right: 1px solid #ccc;
                width: 300px;
                &:last-child{
                  border-right:0;
                }
                .item-detail-block{
                  height: 90px;
                  border-bottom: 1px solid #ccc;
                  margin: 0 15px;
                  &.neg-value{
                    .pretty-number-override-css {
                      color:#ff5e5e !important;
                    }
                  }
                  .month-detail{
                    padding: 4px 5px ;
                    font-size: 14px;
                    .date-block{
                      font-family:$ibmplexRegular;
                      color:#8b8d91;
                      font-size: 14px;
                    }
                    .price-block{
                      display: block;
                      font-size: 14px;
                      &.red{
                        color:#ff5e5e;
                      }
                    }
                  }
                  &:last-child{
                    border-bottom:0;
                  }
                }
              }
            }
          }
        }
        .project-range-block{
          background: #fff;
          flex-flow:nowrap;
          border-right: 1px solid #d7d7d7;
          flex-direction: row !important;
          flex-wrap: nowrap !important;
          .simulation-month-sidebar{
            box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
            -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
            -moz-box-shadow:5px 0 10px -5px rgba(0,0,0,0.4);
          }
          .rh-project-block{
            overflow: hidden;
            position: relative;
            display: flex;
            flex-flow: nowrap;
            .inner-row-block{
              display:  flex;
              flex-flow: nowrap;
              position:relative;
              ul{
                display: flex;
                flex-flow: nowrap;
                .inner-square-block{
                  border-right: 1px solid #d7d7d7;
                  border-bottom:1px solid #d7d7d7;
                  height: 90px;
                  background: #fff;
                }
              }
            }
            .project-dates{
              position: absolute;
              top: 29%;
              border-radius: 10px;
              height: 20px;
              b{
                font-family: $ibmplexRegular;
                font-size: 10px;
                color: #fff;
                position: absolute;
                left: 11px;
                top: 2px;
              }
              img{
                width: 12px;
                position: absolute;
                top: 4px;
                right: 8px;
              }
            }
          }
          &.header-sep-block{
            .spacing-cls{
              padding:10px 10px !important;
              border:1px solid #ccc;
              border-left:0;
              &.month-blk{
                width: 180px;
                flex-basis: 180;
                background: #fff;
                position:relative;
                z-index:1;
                box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
                -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
                -moz-box-shadow:5px 0 10px -5px rgba(0,0,0,0.4);
                border-left:1px solid #ccc;
              }
              &.total-block{
                width: 300px;
                flex-basis: 300;
                background: #fff;
                position:relative;
                z-index:1;
                -webkit-box-shadow: -5px 0 10px -5px rgba(0,0,0,0.4);
                -moz-box-shadow:-5px 0 10px -5px rgba(0,0,0,0.4);
                box-shadow: -5px 0 10px -5px rgba(0,0,0,0.4);
              }
            }
            .flex-shrink-flow{
              flex-grow: 0;
              flex-shrink: 0;
              display: flex;
              .spacing-cls{
                flex-basis: 300px;
                flex-grow: 0;
                flex-shrink: 0;
                &.border-right{
                  border-right: 1px solid #ccc;
                  &:last-child{
                    border-right: none !important;
                  }
                }
              }
            }
          }
          .inner-width-block{
        // table  scss
          .row-header{
            background: #fff;
            margin:0;
            flex-shrink: 0;
            flex-grow: 0;
            flex-wrap: nowrap;
            span{
              font-family:$ibmplexRegular;
              font-size:14px;
              color:#8c9ba5;
              &.caption-ui{
                font-size: 12px;
              }
            }
            p{
              font-family:$ibmplexRegular;
              font-size:14px;
              color:#bd10e0;
            }
            a{
              font-family:$ibmplexRegular;
              font-size:12px;
              color:#2f8cff;
              &:hover{
                color:#2f8cff;
                &:hover{
                  color:#2f8cff;
                }
              }
            }
            .item-block{
              border-bottom: 1px solid #ccc;
              border-right: 1px solid #ccc;
              width: 300px;
              &:last-child{
                border-right:0;
              }
              >.flex-centered{
                padding:10px 15px;
                border-bottom:1px solid #ccc;
                .second-block{
                  width:40%;
                  text-align: right;
                  img{
                    height:40px;
                    width:40px;
                    object-fit: contain;
                  }
                }
              }
              .item-detail-block{
                height: 90px;
                border-bottom: 1px solid #ccc;
                margin: 0 15px;
                &.neg-value{
                  .pretty-number-override-css {
                    color:#ff5e5e !important;
                  }
                }
                .month-detail{
                  padding: 4px 5px ;
                  font-size: 14px;
                  .date-block{
                    font-family:$ibmplexRegular;
                    color:#8b8d91;
                    font-size: 14px;
                  }
                  .price-block{
                    display: block;
                    font-size: 14px;
                    &.red{
                      color:#ff5e5e;
                    }
                  }
                }
                &:last-child{
                  border-bottom:0;
                }
              }
            }
          }
        }
        .simulation-month-sidebar{
          border: 1px solid #ccc;
          border-top: 0;
          border-right: 0;
          position: relative;
          background: #fff;
          box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
          -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
          -moz-box-shadow:5px 0 10px -5px rgba(0,0,0,0.4);
          position: relative;
          z-index: 0;
          .row-header{
            border-left:none;
            .total-block{
              background:#f5f7fa;
              border-bottom:1px solid #ccc;
              &.view{
                padding:10px 3px;
              }
              &.list{
                height: 90px;
                padding-top: 40px;
              }
              p{
                font-family:$ibmplexRegular;
                font-size:14px;
                color:#bd10e0;
                padding:0 15px;
                width:100%;
              }
            }
            .item-detail-block{
              border-bottom: 1px solid #ccc;
              height: 90px;
              .innerheading-block{
                width: 33.3%;
                border-right: 1px solid #d7d7d7;
                justify-content: left;
                .first-block{
                  img{
                    margin:0 auto;
                    display: block;
                  }
                }
                .text-block{
                  font-family: $ibmplexRegular;
                  color:#000;
                  font-size: 14px;
                  .date-block{
                    span{
                      margin-bottom: 4px;
                      display: inline-block;
                    }
                  }
                }
                .status-text-block{
                  margin-bottom:6px;
                  display: inline-block;
                  font-size: 14px;
                }
                .proj-progress{
                  width: 100%;
                  background-color: #ced0da;
                  height: 4px;
                  border-radius: 10px;
                  position: relative;
                  overflow: hidden;
                  top: -10px;
                  span{
                    position: absolute;
                    background-color: #87e665;
                    left: 0;
                    height: 4px;
                    top:0;
                  }
                }
                .month-detail{
                  width:100%;
                  a{
                    font-family:$ibmplexRegular;
                    font-size:11px;
                    color:#2f8cff;
                    &:hover{
                      color:#2f8cff;
                      &:hover{
                        color:#2f8cff;
                      }
                    }
                  }
                  .comment-count {
                    position: absolute;
                    right:10px;
                    top: 15px;
                    float: right;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    span {
                      display: inline-block;
                      font-family: $ibmplexRegular;
                      font-size: 10px;
                      background-color: #4299ff;
                      position: relative;
                      text-align: center;
                      color: #fff;
                      padding: 1px 5px;
                      min-width: 20px;
                      &:before{
                        content: '';
                        position: absolute;
                        border-top: 5px solid #4299ff;
                        border-right: 5px solid transparent;
                        bottom: -5px;
                        left: 3px;
                      }
                    }
                  }
                  .first-block{
                    width:25%;
                  }
                  .second-block{
                    width:75%;
                  }
                  .lessor-logo{
                    width: 46px;
                    margin-right: 15px;
                    display: block;
                    object-fit: cover;
                    max-height: 20px;
                  }
                  span{
                    font-family:$ibmplexSemiBold;
                    font-size: 9px;
                    color: #7f8fa4;
                    &.lead-by{
                      span{
                        font-family:$ibmplexSemiBold;
                        font-size: 9px;
                        color:rgb(51, 122, 183);
                      }
                    }
                  }
                  p{
                    padding:0;
                    line-height: 10px;
                    font-size: 15px;
                    font-family:$ibmplexSemiBold;
                    .proj-name-block{
                      font-weight: normal;
                    }
                  }
                  .table-data{
                    padding-top: 7px;
                    padding-bottom: 7px;
                    th{
                      font-family:$ibmplexSemiBold;
                    }
                  }
                  .status-val-1{
                    color:#ff5e5e;
                    font-family:$ibmplexRegular;
                  }
                  .status-val-2{
                    color:#ffbf00;
                    font-family:$ibmplexRegular;
                  }
                  .status-val-3{
                    color:#008000;
                    font-family:$ibmplexRegular;
                  }
                }
              }
              &.neg-value{
                .pretty-number-override-css {
                  color:#ff5e5e !important;
                }
              }
              p{
                padding:4px 5px;
                font-size: 14px;
                color: #8b8d91;
              }
              &:last-child{
                border-bottom:none;
              }
            }
          }
          }
        }
      }
    }
  }
}
