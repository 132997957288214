@import 'variables';
body{
  .highter{
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fb5353;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgb(251 83 83 / 50%);
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    position: absolute;
    left: 5px;
    top: 10px;
  }
  .projects-list-sec{
    .project-no{
      font-size: 12px;
    }
    .project-name{
      margin-bottom: 6px;
    }
  }
  .form-t004-cn{
    background-color: #144067;
    min-height: 100vh;
    .indicater-detail-block{
      position: absolute;
      top: 180px;
      right: 30px;
      ul{
        li{
          color: #488cc8;
          font-size: 14px;
          margin-bottom: 3px;
          span{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
            margin-right:4px;
            &.poor{
              background-color: #FB5353;
            }
            &.average{
              background-color: #ffae00;
            }
            &.good{
              background-color: #44bc29;
            }
          }
        }
      }
    }
    .model-embarier-165{
      #indicater12{
        .indicater{
          bottom: 15% !important;
          left: 52% !important;
        }
        .section-detail{
          bottom: 13% !important;
          left: 54% !important;
        }
      }
      #indicater21{
        .indicater{
          bottom: 17% !important;
          right: 49.5% !important;
        }
        .section-detail{
          bottom: 15.5% !important;
          left: 48% !important;
        }
      }
    }
    .model-one,.model-two,.model-three,.model-four,.model-nine,.model-ten,.model-seven,.model-five,.model-eight,.model-eleven{
      margin: 60px auto 0;
      min-height: 750px;
      .model-image{
        position: relative;
        min-width: 600px;
        max-width: 780px;
        min-height: 600px;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .technical-project-detail{
    .add-workorder{
      margin-bottom: 20px;
    }
    .project-info-hd{
      background: #ffffff;
      margin: -23px -20px 20px -20px;
      padding: 15px;
      border-radius: 0;
      table{
        td{
          padding-right: 10px;
          &:last-child{
            @include elementStyles($size: 14px, $color: #3f51b5, $fontFamily: $ibmplexRegular);
            cursor: pointer;
          }
        }
      }
      ul{
        float: right;
        li{
          margin-left: 20px;
        }
      }
      h2{
        @include elementStyles($size: 22px, $color: #000000, $fontFamily: $ibmplexSemiBold);
        margin-bottom: 4px;
      }
    }
    .work-order-table{
      width: 100%;
      label{
        @include elementStyles($size: 13px, $color: #717171, $fontFamily: $ibmplexRegular);
        display: block;
        margin-bottom: 3px;
      }
      h6{
        @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
      }
      .work-order-asset{
        img{
          float: left;
          width: 50px;
        }
        >div{
          margin-left: 60px;
          span{
            @include elementStyles($size: 12px, $color: #717171, $fontFamily: $ibmplexRegular);
          }
          h4{
            @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexSemiBold);
            margin-bottom: 4px;
            span{
              font-size: 14px;
              color: #2f8cff;
              padding-left: 5px;
              svg{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .form-table{
      width: 100%;
      background: #FAFAFA;
      border-collapse: collapse;
      tr{
        border-bottom: 1px solid #e5e5e5;
        td{
          padding: 5px 10px 15px;
          h4{
            @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexSemiBold);
            margin-bottom: 4px;
          }
          h6{
            @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
          }
          label{
            @include elementStyles($size: 12px, $color: #717171, $fontFamily: $ibmplexRegular);
            display: block;
            margin-bottom: 3px;
          }
          .user-info{
            @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
            .avator-icon{
              margin-right: 6px;
            }
          }
        }
      }
    }
    .project-date-card{
      padding: 20px 0;
      label{
        @include elementStyles($size: 13px, $color: #717171, $fontFamily: $ibmplexRegular);
      }
      h6{
        @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular);
      }
      h3{
        @include elementStyles($size: 28px, $color: #000000, $fontFamily: $ibmplexSemiBold);
      }
    }
    .cor-feedback{
      padding: 15px;
      margin-bottom: 20px;
      cursor: pointer;
      h4{
        svg{
          margin-left: auto;
        }
      }
    }
    .work-order-users{
      background: #fff;
      margin-bottom: 20px;
      padding: 10px 0;
      h4{
        margin-bottom: 10px;
        padding: 0 15px;
      }
      ul{
        li{
          border-bottom: 1px solid #d7d7d7;
          padding: 8px 15px;
          .user-details{
            margin-left: 10px;
            h5{
              @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular);
              svg{
                cursor: pointer;
                display: none;
              }
              &:hover{
                svg{
                  display: inline-block;
                }
              }
            }
            span{
              @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
            }
          }
          &:last-child{
            border-bottom: none;
          }
        }
      }
    }
  }
  .technical-aircraft-froms{
    .sort-image-cn{
      ul.list-inline{
        display: inline !important;
      }
    }
    .drag-drop-cnt{
      background: transparent;
      p{
        @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
      }
    }
    .form-t002-cn, .form-t001-cn{
      h4{
        @include elementStyles($size: 16px, $color: #691ad3, $fontFamily: $ibmplexRegular, $margin: 0 0 20px 0);
        span{
          padding-left: 20px;
          color: #368ffb;
          cursor: pointer;
        }
      }

      &.form-t001-cn{
        ul.list-inline{
          li{
            margin-right: 15px;
            margin-bottom: 15px;
            &:first-child{
              margin-left: 0;
            }
          }
        }
      }
    }
    .form-t003-cn{
      margin-bottom: 30px;
      h2{
        @include elementStyles($size: 16px, $color: #691ad3, $fontFamily: $ibmplexRegular, $margin: 0 0 16px 0);
      }
      .MuiExpansionPanelSummary-content{
        @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
      }
      .MuiExpansionPanelDetails-root{
        padding: 0 !important;
      }
      .t003-docs{
        width: 100%;
        table{
          width: 100%;
          thead{
            background: #ebebeb;
            th{
              @include elementStyles($size: 12px, $color: #8c9ba5, $fontFamily: $ibmplexRegular);
              text-align: left;
              padding: 5px 25px;
            }
          }
          tbody{
            tr{
              &:last-child{
                border-bottom: none;
              }
              td{
                padding: 10px 25px;
                border-bottom: 1px solid #d7d7d7;
                ul.spacing-list{
                  li{
                    margin-right: 10px;
                  }
                }
                h4{
                  @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 6px 0);
                }
                span{
                  @include elementStyles($size: 12px, $color: #2f8cff, $fontFamily: $ibmplexRegular);
                  cursor: pointer;
                }
                .attached-files{
                  .file-listing{
                    >li{
                      position: relative;
                      background: #e8f5fd;
                      padding: 3px 40px 3px 3px;
                      border-radius: 3px;
                      cursor: default;
                      display: inline-block;
                      margin-bottom: 4px;
                      font-size: 11px;
                      margin-left: 0 !important;
                      .file-action{
                        position: absolute;
                        right: 1px;
                        top: 2px;
                        li{
                          cursor: pointer;
                          margin-left: 3px;
                          svg{
                            font-size: 16px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-t005-cn{
      .MuiTabs-root{
        .MuiTab-root{min-width: 200px !important}
        button{
          font-size: 12px;
        }
      }
      .is-fitted-fields{
        position: relative;
        .checkbox-label{
          position: absolute;
          top: -16px;
          .MuiTypography-root{
            font-size: 12px;
          }
        }
      }
    }
    .form-t008-cn{
      background: #144067;
      margin: -17px -20px -17px -20px;
      .edit-config{
        padding: 0 10px;
        .seat-window-sw{
          li{
            &.active{
              @include elementStyles($size: 13px, $color: #ffffff, $fontFamily: $ibmplexMedium);
            }
            &.non-active{
              @include elementStyles($size: 13px, $color: rgba(255,255,255,0.5), $fontFamily: $ibmplexRegular);
            }
          }
        }
        .seat-config-options{
          float: right;
          li{
            @include elementStyles($size: 13px, $color: #ffffff, $fontFamily: $ibmplexRegular);
            margin-left: 20px;
            padding: 2px 0;
            cursor: pointer;
            &:first-child{
              margin-left: 0;
            }
          }
        }
      }
      .seats-container {
        min-height: 100vh;
        width: 100%;
        overflow-x: scroll;
        margin-top: 5px;
        .seat-matrix {
          padding: 0 20px;
          display: none;
          align-items: center;
          .background-image-block {
            background-repeat: no-repeat;
            flex-shrink: 0;
            position: relative;
            width: 605px;
            height: 640px;
            background-position: -11px -2px;
            background-size: cover;
            position: relative;
            top: -23px;
            .cockpit-seats,.attended-seats {
              li {
                position: absolute;
                transform: translate(-50%, -50%);
                .disabled-seat {
                  opacity: 0.2 !important;
                  cursor: not-allowed;
                }
                .current {
                  background-color: #488cc8;
                  color: #fff;
                }
                .finding-active {
                  background-color: #488cc8;
                  color: #fff;
                }
                .has-finding {
                  background-color: #ff6c6c;
                }
                &.fo-seat {
                  position: absolute;
                  right: 28%;
                  top: 25%;
                }
                &.cls-seat {
                  position: absolute;
                  right: 44%;
                  top: 37%;
                }
                &.o2-seat {
                  position: absolute;
                  right: 44%;
                  bottom: 34%;
                }
                &.o1-seat {
                  position: absolute;
                  right: 28%;
                  bottom: 25%;
                }
                a{
                  height: 30px;
                  min-width: 32px;
                  background-color: transparent;
                  margin-bottom: 10px;
                  padding: 6px 8px 5px;
                  border-radius: 4px;
                  display: inline-block;
                  border: 1px solid #144067;
                  color: #144067;
                  font-family: $ibmplexRegular;
                  font-size: 12px;
                  cursor: pointer;
                  text-align: center;
                  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                  box-sizing: border-box;         /* Opera/IE 8+ */
                  &.active {
                    background-color: #488cc8;
                  }
                }
              }
            }
          }
          .cabin-seats{
            display: flex;
            align-items: center;
            height: 685px;
            position: relative;
            left: -13px;
            flex-shrink: 0;
            padding-right: 20px;
            img {
              &.cabin-image {
                position: absolute;
                width: 100%;
                top: -1px;
                bottom: 0;
                height: 638px;
                left: 12px;
              }
            }
            .seat-list {
              white-space: nowrap;
              margin-left: 20px;
              &.next-seat-block{
                margin-right:40px;
              }
              &:first-child {
                margin-left: 200px;
              }
              li {
                ul {
                  margin-left: 0;
                  padding-top: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  li {
                    a {
                      position: relative;
                      height: 30px;
                      min-width: 40px;
                      background-color: transparent;
                      margin-bottom: 10px;
                      padding: 6px 8px 5px;
                      border-radius: 4px;
                      display: inline-block;
                      border: 1px solid #144067;
                      color: #144067;
                      font-family: $ibmplexRegular;
                      font-size: 12px;
                      text-align: center;
                      text-transform: uppercase;
                      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                      -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                      box-sizing: border-box;         /* Opera/IE 8+ */
                      &.current {
                          background-color: #488cc8;
                          color: #fff;
                      }
                      &.finding-active {
                          background-color: #488cc8;
                          color: #fff;
                      }
                      &.has-finding {
                          background-color: #ff6c6c;
                      }
                      &.disabled-seat {
                          opacity: 0.2 !important;
                          cursor: not-allowed;
                      }
                      &.window {
                        // opacity: .2;
                        background-position: center;
                        background-size: cover;
                        border: 1px solid rgba(20, 64, 103, 1);
                        background-image: url("https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/window_inactive.png");
                      }
                      &.window-selected {
                        background-image: url("https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/window_active.png");
                        border: 1px solid rgba(20, 64, 103, 1);
                      }
                    }
                    &:first-child {
                      a {
                        position: absolute;
                        top: 3%;
                      }
                    }
                    &:last-child {
                      a {
                        position: absolute;
                        bottom: 10%;
                      }
                    }
                    &:first-child, &:last-child {
                      a {
                        height: 15px;
                        transform: translateX(0px);
                        -webkit-transform: translateX(0);
                        -moz-transform: translateX(0);
                      }
                    }
                    &:first-child {
                      margin-bottom: 10px;
                    }
                    &:last-child {
                      margin-top: 10px;
                      a {
                        margin-bottom: 0;
                      }
                    }
                    &.column-seperator {
                      margin-bottom: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-t011-cn{
      .prs-heading{
        h2{
          @include elementStyles($size: 20px, $color: #bd10e0, $fontFamily: $ibmplexRegular, $margin:0 0 10px 0);
        }
        ul{
          float: right;
          li{
            display: flex;
            margin-left: 15px;
            cursor: pointer;
            .list-bar-layout{
              display: inline-block;
              border: 1px solid #e2e2e2;
              border-radius: 3px;
              background: #e2e2e2;
              .active{
                background: #c6d0f7;
              }
              .list-layout{
                display: inline-block;
                padding: 2px 10px;
              }
              .bar-layout{
                display: inline-block;
                padding: 2px 10px;
              }
            }
          }
        }
      }
      .table-items-selection{
        margin-bottom: 10px;
        ul{
          li{
            cursor: pointer;
            display: flex !important;
            span.action-menu{
              padding-left: 1px;
            }
            &:first-child{
              width: 240px;
              display: inline-block !important;
            }
            margin-right: 10px;
          }
        }
      }
      .table-bulk-ops{
        ul{
          li{
            margin-right: 15px;
            &.action-list{
              min-width: 200px;
            }
          }
        }
      }
      .prs-count{
        padding: 15px 15px 10px 15px;
        h4{
          @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        p{
          @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        &.total-count{
          border-left: 10px solid #043bfc;
          h4{
            color:#043bfc;
          }
          p{
            color:#043bfc;
          }
        }
        &.open-count{
          border-left: 10px solid #7bb4ec;
          h4, p{
            color:#7bb4ec;
          }
        }
        &.closed-count{
          border-left: 10px solid #90ed7d;
          h4,p{
            color:#90ed7d;
          }
        }
        &.reopen-count{
          border-left: 10px solid #f25d80;
          h4,p{
            color:#f25d80;
          }
        }
      }
      .prs-filter{
        >ul{
          float: right;
          >li{
            margin-left: 15px;
            &.action-list{
              min-width: 140px;
            }
          }
        }
      }
      .prs-tabling{
        .mui-styled-table{
          >thead{
            th{
              background: #f0f2f3
            }
          }
          .MuiTableBody-root{
            tr{}
          }
        }
      }
    }
  }
  .technical-engine-forms{
    .image-sorting-cn{
      ul.list-inline{
        display: inline !important;
      }
    }
    .gen-detail-title{
      @include elementStyles($size: 16px, $color: #691ad3, $fontFamily: $ibmplexRegular);
      margin-bottom: 10px;
      .sv-list-link{
        font-size: 13px;
        text-decoration: underline;
        float: right;
      }
      span{
        color: #2f8cff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .form-detail-card{
      padding: 20px;
      margin-bottom: 30px;

    }
    .inspection-picture-list{
      li{
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
    .accessories-list-table{
      table{
        th, td{
          table{
            thead, tr, th{
              background: transparent !important;
            }
          }
        }
      }
    }
    .engine-te004-form{
      margin-bottom: 30px;
      h3.title{
        @include elementStyles($size: 16px, $color: #691ad3, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
      }
      p.para{
        @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 4px 0);
      }
    }
    .engine-te006-sections{
      .working-groups-section{
        margin-bottom: 30px;
      }
      .MuiExpansionPanelDetails-root{
        display: block;
      }
      .ck-editor{
        width: 100% !important;
        display: block;
      }
      .remove-section{
        display: inline-block;
        clear: both;
      }
    }
  }
  .add-technical-engine{
    h2{
      @include elementStyles($size: 24px, $color: #000000, $fontFamily: $ibmplexRegular);
      margin-bottom: 16px;
    }
    .MuiTab-wrapper{
      font-size: 13px;
    }
    .papar-card{
      padding: 20px;
      &.form-list-selection{
        margin-bottom: 30px;
      }
      .cta-cnt{
        button{
          float: right;
          margin-bottom: 20px;
        }
      }
      .shop-visit-card{
        border: 1px solid #d7d7d7;
        clear: both;
        padding: 15px;
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .inspection-form-wrapper{
    .inspection-form-card{
      border-radius: 0;
      &:first-child{
        border-bottom: 1px solid #d7d7d7;
      }
      .inspection-asset-info{
        border-right: 1px solid #d7d7d7;
        padding-left: 10px;
        h4{
          position: relative;
          top: 5px;
          @include elementStyles($size: 16px, $color: #212529, $fontFamily: $ibmplexRegular);
          svg{
            padding-right: 5px;
            cursor: pointer;
          }
          span{
            padding-left: 6px;
            @include elementStyles($size: 12px, $color: #8c8787, $fontFamily: $ibmplexRegular);

          }
        }
      }
      .inspection-project-info{
        padding: 3px 0 3px 15px;
        a{
          cursor: pointer;
          @include elementStyles($size: 12px, $color: #2f8cff, $fontFamily: $ibmplexRegular);

        }
        h4{
          a{
            @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular);
            text-decoration: underline;
          }
          span{
            color: #bd10e0;
          }
        }

      }
      .inspection-form-info{
        width: 250px;
        border-right: 1px solid #d7d7d7;
        padding: 4px 10px;
        h2{
          @include elementStyles($size: 20px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        .form-name{
          @include elementStyles($size: 11px, $color: #a0a0a0, $fontFamily: $ibmplexRegular, $margin: 0 0 4px 0);
        }
      }
      .inspection-form-cta{
        padding-left: 15px;
        button{
          margin-right: 10px;
        }
      }
      .inspection-header-left{
        ul{
          float: right;
          li{
            margin-right: 22px;
            p{
              @include elementStyles($size: 12px, $color: #717171, $fontFamily: $ibmplexRegular);
            }
            h6{
              @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
            }
          }
        }
      }
    }
  }
  .forms-download-logs{
    h1{
      @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular, $padding: 15px);
      border-bottom: 1px solid #d7d7d7;
      span{
        float: right;
        cursor: pointer;
      }
    }
    .logs-list{
      padding: 15px;
      p{
        @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular, $padding:12px 0px);
        border-bottom: 1px solid #d7d7d7;
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
  .image-gallery-content{
    text-align: center;
    .image-gallery-thumbnails-wrapper{
      .image-gallery-thumbnail-image{
        width: 100px;
        height: 100px;
      }
    }
  }
  .forms-list-cn{
    max-height: 500px;
    overflow: auto;
    li{
      border-bottom: 1px solid #d7d7d7;
      &:last-child{
        border-bottom: none;
      }
    }
    .forms-dropdown-list{
      width: 225px;
      h4{
        @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular);
      }
      p{
        @include elementStyles($size: 12px, $color: #7a7a7a, $fontFamily: $ibmplexRegular);
      }
    }
  }
  .project-cor-form{
    .project-cor-header{
      margin-bottom: 10px;
      h1{
        a{
          @include elementStyles($size: 24px, $color: #000000, $fontFamily: $ibmplexRegular);
          display: inline-block;
        }
      }
      ul{
        float: right;
        li{
          margin-left: 15px;
        }
      }
    }
    h2{
      @include elementStyles($size: 18px, $color: #212529, $fontFamily: $ibmplexRegular, $padding:0px 10px);
      span{
        cursor: pointer;
        position: relative;
        top: 3px;
        left: 5px;
      }
    }
    .cor-form-section{
      h3{
        background: #f0f2f6;
        padding: 15px;
        @include elementStyles($size: 18px, $color: #7f8fa4, $fontFamily: $ibmplexRegular);
      }
      .cor-form-question{
        background: #ffffff;
        border-bottom: 1px solid #d7d7d7;
        padding: 15px;
        h4{
          @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 16px 0);
        }
        .cor-form-user{
          border-bottom: 1px solid #d7d7d7;
          padding: 15px 0 15px 15px;
          background: #f1f1f1;
          &:last-child{
            border-bottom: none;
          }
          h5{
            @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
            .MuiAvatar-root{
              width: 30px;
              height: 30px;
              margin-right: 6px;
            }
          }
          ul{
            li{
              margin-right: 20px;
              &:last-child{
                min-width: 500px;
              }
            }
          }
        }
      }
    }
  }
  .technical-review-forms-cn{
    width: 70%;
    margin: 0 auto;
    .t001-images{
      padding: 15px;
      border-bottom: 1px solid #d7d7d7;
      min-height: 200px;
      background: #ffffff;
      h3{
        @include elementStyles($size: 16px, $color: #212529, $fontFamily: $ibmplexRegular, $margin:0 0 20px 0);
      }
      ul{
        li{
          margin-bottom: 20px;
          .add-issue{
            float: right;
            cursor: pointer;
          }
          img.section-image{
            object-fit: contain;
            height: 300px;
            width: 400px;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    .t003-section{
      h3{
        @include elementStyles($size: 24px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
      }
      .t003-card{
        h4{
          @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
        }
        background: #ffffff;
        padding: 15px;
        .t003-question{
          border: 1px solid #d7d7d7;
          border-bottom: none;
          padding: 10px;
          p{
            @include elementStyles($size: 14px, $color: #212529, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
          }

          &:last-child{
            border-bottom: 1px solid #d7d7d7;
          }
        }
      }
      .add-issue{
        cursor: pointer;
        float: right;
      }
    }
    .t006-section{
      .add-issue{
        cursor: pointer;
        float: right;
      }
    }
    .t005-technical-specs{
      background: #ffffff;
      padding: 15px;
      h1{
        @include elementStyles($size: 18px, $color: #bd10e0, $fontFamily: $ibmplexRegular, $margin: 0 0 16px 0);
      }
    }
    .t007-findings{
      background: #ffffff;
      padding: 15px;
      .section-info{
        h3{
          @include elementStyles($size: 16px, $color: #2f8cfe, $fontFamily: $ibmplexRegular, $margin: 0 0 16px 0);
        }
        .findings-info{
          margin-bottom: 20px;
          table{
            margin-bottom: 20px
          }
          .findings-img{
            max-width: 80%;
          }
        }
      }
    }
    .t009-section{
      margin-bottom: 20px;
      p{
        padding: 10px;
        .part-number{
          float: left;
          margin-right: 10px;
          background: #4a90e2;
        }
      }

      .add-issue{
        float: right;
        cursor: pointer;
      }
    }
  }
  .engine-workscope-module-table{
    .border-right{
      border-right: 1px solid #dee2e6;
    }
    .border-left{
      border-left: 1px solid #dee2e6;
    }
    .workscope-table-heading{
      background: #fafafa;
      border: 1px solid #dfe3e6;
      margin: 0;
      overflow: visible;
      h6{
        padding: 6px 6px;
        @include elementStyles($size: 13px, $color: #6d9bb9, $fontFamily: $ibmplexMedium);
      }
      .workscope-level{
        h6{
          border-bottom: 1px solid #dfe3e6;
          text-align: center;
        }
      }
    }
    .engine-workscope-table{
      background: #fff;
      .add-new-row{
        @include elementStyles($size: 12px, $color: #3f51b5, $fontFamily: $ibmplexRegular);
        text-decoration: underline;
        cursor: pointer;
      }
      .del-row{
        margin: 0 auto;
        cursor: pointer;
      }
      .workscope-row{
        border-bottom: 1px solid #d7d7d7;
        &:nth-child(even){
          background: #f5f7fa;
        }
        &:nth-child(odd){
          background: #ebeff6;
        }
        .module-col{
          position: relative;
          .module-content{
            padding: 15px;
          }
        }
        .workscope-sub-row{
          border-bottom: 1px solid #d7d7d7;
          &:last-child{
            border-bottom: none;
          }
          .submodule-col{
            .submodule-content{
              padding: 15px;
            }
          }
          .workscope-item-row{
            border-bottom: 1px solid #d7d7d7;

            .item-col{
              .item-content{
                width: 100%;
                padding: 15px;
              }
              &.workscope-level{

              }
            }

          }
        }
      }
    }
  }
  .t008-specs-modal{
    background: #f7f7f7;
    padding: 15px;
    margin-top: 30px;
    h4{
      @include elementStyles($size: 14px, $color: #9439e4, $fontFamily: $ibmplexSemiBold);
      margin-bottom: 22px;
    }
  }
  .observation-table{
    tr{
      td{
        padding: 8px 0;
        border-bottom: 1px solid #d7d7d7;
        label{
          .MuiFormControlLabel-label{
            color: rgba(0, 0, 0, 0.54);
          }
        }
        &:last-child{
          padding-left: 30px;
        }
      }
      &:last-child{
        td{
          &:last-child{
            padding-left: 0;
          }
        }
      }
    }
  }
  .read-prerequisite-modal{
    ol{
      margin-left: 15px;
      li{
        @include elementStyles($size: 12px, $color: #202020, $fontFamily: $ibmplexLight, $margin: 0 0 10px 0);
      }
    }
  }
  .project-engineer-profile-modal{
    min-width: 480px;
    padding: 20px 0;
    .MuiAvatar-root{
      margin:  0 auto;
    }
    table{
      width: 100%;
      background: #f1f1f1;
      margin-top: 20px;
      td{
        padding:5px 10px;
        @include elementStyles($size: 12px, $color: #7a7a7a, $fontFamily: $ibmplexRegular);
        &:last-child{
          text-align: right;
          color: #000000;
        }
      }
    }
  }
  .add-edit-project-modal{
    .project-contact{
      margin-bottom: 20px;
      h4{
        @include elementStyles($size: 14px, $color: #691ad3, $fontFamily: $ibmplexSemiBold);
        margin-bottom: 10px;
        button{
          margin-left: 10px;
          font-size: 10px;
        }
      }
    }
    .for-label{
      @include elementStyles($size: 14px, $color: #691ad3, $fontFamily: $ibmplexSemiBold);
      margin-bottom: 10px;
    }
  }
  .tracker-info-popover{
    h3{
      background: #f7f7f7;
      @include elementStyles($size: 14px, $color: #202020, $fontFamily: $ibmplexRegular, $margin: 0 0 4px 0);
      padding: 10px;
    }
    .list-card{
      padding: 10px;
      span{
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 4px;
      }
      ul{
        li{
          @include elementStyles($size: 12px, $color: #202020, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
          margin-bottom: 8px;
          ul{
            margin-left: 20px;
          }
        }
      }
    }

  }
  .t004-finding-specs-modal{
    .t004-questios-answer-section{
      background: #f1f1f1;
      padding: 15px;
      margin-bottom: 10px;
      .delete-comp{
        float: right;
        cursor: pointer;
      }
    }
  }
  .add-disassembly-modal{
    .image-sorting-cn{
      ul.list-inline{
        &.inspection-picture-list{
          li{
            margin-right: 15px;
            margin-bottom: 15px;
          }
        }
        display: inline !important;
      }
    }
  }
  .aircaft-seat-config-modal{
    .aircraft-section{
      padding-bottom: 15px;
      padding-top: 15px;
      border-bottom: 1px dashed #3f51b5;
      position: relative;
      .remove-section{
        position: absolute;
        right: 0px;
        top: 5px;
        cursor: pointer;
      }
      &:last-child{
        margin-bottom: 20px;
      }
      // &:nth-child(even){
      //   background-color: #f1f1f1;
      // }
      // &:nth-child(odd){
      //   background-color: #fafafa;
      // }
      p{
        span{cursor: pointer;}
      }
      .seat-number{
        li{
          margin-right: 10px;
          text-align: center;
          input{text-align: center !important;}
        }
      }
    }
    button.add-new-section{
      margin-top: 20px;
    }
  }
  .t011-add-modal{
    .t011-report-import-notes{
      border: 1px solid #a78701;
      background: #fff1b6;
      padding: 15px;
      border-radius: 4px;
      h4{
        @include elementStyles($size: 16px, $color: #a78701, $fontFamily: $ibmplexLight, $margin: 0 0 8px 0);
      }
      ul{
        margin-left: 20px;
        li{
          @include elementStyles($size: 13px, $color: #a78701, $fontFamily: $ibmplexLight, $margin: 0 0 3px 0);
        }
      }
    }
  }
  .prs-deleted-logs{
    padding: 0;
    width: 440px;
    h4{
      position: relative;
      @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexMedium, $margin: 0 0 8px 0, $padding:15px);
      border-bottom: 1px solid #c4c4c4;
      span{
        position: absolute;
        right: 10px;
        top:35%;
        cursor: pointer;
      }
    }
    ul{
      padding: 20px;
      overflow: auto;
      li{
        display: inline-block;
        padding: 10px 0;
        border-bottom: 1px solid #c4c4c4;
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
  // Form Review

  .forms-review-issues-section{
    h1{
      @include elementStyles($size: 20px, $color: #000000, $fontFamily: $ibmplexRegular, $padding: 10px);
      border-bottom: 1px solid #d7d7d7;
      span{
        cursor: pointer;
        float: right;
      }
    }
    .date{
      @include elementStyles($size: 12px, $color: #727272, $fontFamily: $ibmplexRegular);
    }
    .issues-tabs{
      width: 100%;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 16px;
      li{
        padding: 10px 20px;
        cursor: pointer;
        &.active{
          color: #3f51b5;
          border-bottom: 1px solid #3f51b5;
        }
      }
    }
    ul.cta-list{
      clear: both;
      float: right;
      li{
        margin-left: 8px;
        cursor: pointer;
      }
    }
    p{
      @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular, $margin:8px 0 15px 0);
      clear: both;
    }
    .resolved-info{
      span{
        @include elementStyles($size: 13px, $color: #7ed321, $fontFamily: $ibmplexRegular);
      }
    }
    .form-issues-list{
      .form-issues-card{
        border-bottom: 1px solid #d7d7d7;
        padding: 10px;
        .issue-id{
          float: left;
          width: 30px;
          height: 30px;
          position: relative;
          @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexSemiBold);
          &.resolved-id{
            background:#7ed321;
            color: #ffffff;
          }
        }
        .form-issue-info{
          margin-left: 40px;
          overflow: hidden;
          h4{
            @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexSemiBold);
          }
        }
      }
    }
    .issue-discussion-list{
      padding: 10px 10px 10px 40px;
      .issue-discuss{
        overflow: hidden;
        border-bottom: 1px solid #d7d7d7;
        padding: 10px;
      }
    }
  }

}
@media only screen and (max-width: 600px){
  .inspection-form-wrapper{
    margin: -30px -20px 20px -20px;
    .inspection-asset-info{
      padding-right: 10px;
      padding-left: 0 !important;
    }
    .inspection-header-left{
      padding: 10px 10px 0 15px;
      ul{
        li{
          height: 50px;
          width: 40%;
          display:inline-block;
        }
      }
    }
    .inspection-form-card{
      padding: 10px 15px 0 15px;
      ul{
        display: block !important;
      }
      .inspection-form-info, .inspection-form-cta{
        width: 100% !important;
        display: block;
        margin: 0 !important;
        padding: 0 !important;
        &.inspection-form-info{
          border-right: none !important;
          margin-bottom: 10px !important;

        }
      }
      .inspection-header-left{
        padding: 10px 0 0 0 !important;
        ul{
          float: none !important;
        }
      }
    }
  }
  .technical-aircraft-froms{
    .form-t001-cn{
      ul.list-inline{
        width: 100%;
        li{
          display: inline-block;
          width: 100%;
          >div{
            margin: 0 auto;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 960px) and (min-width: 600px){

}
@media only screen and (max-width: 1280px) and (min-width: 960px){
  .inspection-form-wrapper{
    // position: fixed;
    // right: 0;
    // left: 70px;
    // top: 55px;
    // z-index: 99;
    .inspection-asset-info{
      width: 260px;
    }
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1280px){
  .inspection-form-wrapper{
    position: fixed;
    right: 0;
    left: 70px;
    top: 50px;
    z-index: 99;
    .inspection-asset-info{
      width: 260px;
    }
  }
}
@media only screen and (min-width: 1920px){
  .inspection-form-wrapper{
    position: fixed;
    right: 0;
    left: 70px;
    top: 50px;
    z-index: 99;
    .inspection-asset-info{
      width: 260px;
    }
  }
}
