@import 'variables';
body{
  .MuiDrawer-paper{
    // background: linear-gradient(0deg,#7c52e9,#2c92ff);
  }
  .desktop-py-sidenav{
    background: #6B5EB0;
    position: fixed;
    top: 49px;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 70px;
    text-align: center;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }
    nav{
      ul{
        li{
          margin-bottom: 8px;
          a{
            display: block;
            width: 80%;
            margin: 0 auto;
            padding: 8px 5px;
            img{
              width: 20px;
              margin-bottom: 4px;
            }
            p{
              @include elementStyles($size: $font12, $color: #fff, $fontFamily: $ibmplexRegular);
              line-height: 16px;
            }
            &:hover{
              background: rgba(0,0,0,.2);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  .notification-menu-list{
    li.list-inline-item{
      margin-left: 20px;
      &:first-child{
        margin-left: 0;
      }
      img{
        width: 20px;
      }
    }
  }
  .desktop-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid #d7d7d7;
    background: #fff;
    z-index: 999;
    padding: 8px 0;
    .app-logo-search{
      li{
        margin-right: 10px;
        .app-switcher{
          @include elementStyles($size: $font16, $color: #bd10e0, $fontFamily: $ibmplexMedium);
        }
      }
      .app-logo{
        a{
          padding: 0 17px;
          img{
            width: 32px;
          }
        }
      }
      form{
        input[type="text"]{
          background: #E7EAEA;
          border: none;
          box-shadow: none;
          height: 38px;
          width: 420px;
          border-radius: 4px;
          padding: 0 15px;
          &:focus{
            outline: none;
          }
        }
      }
    }
    .header-right-nav{
      float: right;
      >.list-inline-item{
        margin-left: 1px;
        padding: 0px 15px;
        &:first-child{
          margin-left: 0;
          border-left: none;
        }
        &:last-child{
          border-left: 1px solid #ddd;
        }
        .user-menu-dropdown{
          button{
            img{
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            h3{
              font-size: 14px;
              line-height: 12px;
              text-transform: none;
            }
            span{
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .mobile-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #d7d7d7;
    z-index: 100;
    ul{
      float: right;
      li{
        margin-left: 16px;
        a{
          img{
            width: 16px;
          }
        }
      }
    }
  }
  .mobile-main-navs{
    width: 250px;
    // padding: 10px;
    background: linear-gradient(0deg, #7c52e9, #2c92ff);
    height: 100%;
    overflow: auto;
    .user-info{
      padding: 10px;
      border-radius: 0;
      // margin-bottom: 10px;
      .user-card{
        .user-avatar{
          float: left;
          margin-right: 4px;
        }
        h3{
          @include elementStyles($size: $font16, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        span{
          @include elementStyles($size: $font13, $color: #000000, $fontFamily: $ibmplexRegular);
        }
      }
    }
    .mobile-py-link{
      padding: 10px;
      background: transparent;
      ul{
        li{
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
          a{
            display: flex;
            align-items: center;
            cursor: pointer;

            img{
              width: 20px;
              margin-right: 10px;
            }
            p{
              display: inline;
              @include elementStyles($size: $font14, $color: #fff, $fontFamily: $ibmplexRegular);
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .secondary-sidenav{
    background-image: linear-gradient(-180deg,#6538db,#0a56a8);
    width: 200px;
    position: fixed;
    height: 100%;
    z-index: 99;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }
    h4{
      padding: 10px 15px;
      margin: 8px 0;
      @include elementStyles($size: $font16, $color: #f0a4ff, $fontFamily: $ibmplexRegular);
    }
    ul{
      li{
        a{
          display: block;
          padding-left: 32px;
          cursor: pointer;
          padding: 10px 15px;
          @include elementStyles($size: $font12, $color: #fff, $fontFamily: $ibmplexRegular);
          &:hover, &.active{
            background-image: linear-gradient(90deg,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0));
          }
        }
      }
    }
  }
  .mobile-secondary-drawer{
    padding: 5px 15px 2px 15px;
    background-image: linear-gradient(-180deg,#6538db,#0a56a8);
    color: #fff;
    svg{
      cursor: pointer;
    }
  }
  .mobile-secondary-manulist{
    h4{
      padding: 10px 15px;
      font-size: 16px;
      color: #f0a4ff;
      margin: 8px 0;
    }
    ul{
      li{
        a{
          display: block;
          padding-left: 32px;
          cursor: pointer;
          font-size: 12px;
          color: #fff;
          padding: 10px 15px;
          &:hover, &.active{
            background-image: linear-gradient(90deg,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0));
          }
        }
      }
    }
  }
  footer{
    background: #191c26;
    padding: 50px 0;
    h4{
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      margin: 0 0 15px 0;
      clear: both;
    }
    a{
      font-size: 13px;
      color: #fff;
      font-weight: 400;
      display: inline-block;
    }
    ul.list-unstyled{
      li{
        a{
          padding: 8px 0;
        }
      }
    }
    ul.list-inline{
      margin-bottom: 15px;
      display: inline-block;
      li{
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .footer-copyright{
      img{
        width: 120px;
        margin-bottom: 10px;
      }
      p{
        font-size: 13px;
        font-weight: 300;
        color: #fff;
      }
    }
  }
}
