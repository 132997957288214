@import 'variables';
body{
  .report-export-section{
    background: red;
    padding: 10px;
    .export-module-card{
      position: relative;
      z-index: 99;
      box-shadow: 0 2px 4px rgba(0,0,0,0.6);
      overflow: hidden;
      background: #fff;
      margin-bottom: 20px;
      border-radius: 4px;
      .rpt-card-header{
        border-bottom: 1px solid #d7d7d7;
        padding:10px 15px;
        h2{
          font-size: 24px;
        }
      }
      .rpt-card-body{
        padding: 15px;
        .sub-mod-title{
          font-size: 20px;
          margin-bottom: 10px;
        }
        .sub-mod-card{
          margin-left: 10px;
        }
        .export-items{
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #d7d7d7;
          &:last-child{
            border-bottom: none;
          }
        }
        .export-label{
          margin-bottom: 10px;
          span{
            font-size: 18px;
            padding-left: 6px;
          }
        }
        .export-avial{
          margin-left: 20px;
          h6{
            font-size: 14px;
            margin-bottom: 4px;
          }
          ul{
            display: inline-block;
            li{
              margin-right: 15px;
              label{
                span{
                  padding-left: 6px;
                }
              }
            }
          }
        }
        .filter-avail{
          margin-top: 10px;
          background: #eaeaea;
          margin-left: 20px;
          ul{
            display: inline-block;
          }
        }
      }
    }
  }
  .auth-sec-hd{
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 15px 0;
    ul.list-inline{
      float: right;
      li{
        @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 400, $color: $whiteColor, $margin: 0 0 0 15px);
        &:first-child{
          margin-left: 0;
        }
        &.sign-in{
          margin-left: 8px;
        }
        a{
          @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 400, $color: $whiteColor, $padding: 5px 15px);
          &.sign-in-link{
            padding-left: 0px;
            font-weight: 600;
          }
        }
        // &:last-child{
        //   a{
        //     border: 1px solid #fff;
        //     border-radius: 4px;
        //   }
        // }
      }
    }
  }
  .auth-section{
    background-position: 50%;
    background-repeat: no-repeat;
    display: block;
    background-image: url('https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/banner.jpg');
    background-size: cover;
    min-height: 100vh;
    position: relative;
    .auth-inner-blk{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
        }
    .auth-help-text{
      h1{
        @include elementStyles($size: $font36, $fontFamily: $robotoFamily, $weight: 500, $color: $whiteColor, $margin: 0 0 20px 0);
      }
      p{
        @include elementStyles($size: $font24, $fontFamily: $robotoFamily, $weight: 300, $color: $whiteColor, $margin: 0 0 20px 0);
        opacity: 0.63
      }
    }
    .auth-form-wrapper{
      background: #fff;
      padding: 20px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,.23);
      border-radius: 4px;
      &.lessor-selection-from{
        margin-top: 50px;
      }
      a{
        @include elementStyles($size: 12px, $fontFamily: $robotoFamily, $weight: 400, $color: #0093ff, $margin: 0 0 10px 0);
        display: inline-block;
      }
      .form-title{
        @include elementStyles($size: 21px, $fontFamily: $robotoFamily, $weight: 400, $color: #4f6276, $margin: 0 0 15px 0);
      }
      .form-para{
        @include elementStyles($size: 15px, $fontFamily: $robotoFamily, $weight: 400, $color: #212529, $margin: 0 0 16px 0);
        &.otp{
          font-size: 13px;
          font-weight: bold;
          margin-top: 16px;
        }
      }
      .terms-and-condition{
        @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 500, $color: #000000, $margin: 0 0 16px 0);
        letter-spacing: 0.5px;
        .url{
          margin: auto;
          font-size: 14px;
          line-height: 1.5;
        }
      }
      form{
        .forgot-pwd-link{
          @include elementStyles($size: $font13, $fontFamily: $robotoFamily, $weight: 400, $color: #0093ff, $margin: 0px 0 0px 0);
          float: right;
        }
        .terms-policy{
          @include elementStyles($size: $font13, $fontFamily: $robotoFamily, $weight: 400, $color: #010101, $margin: 20px 0 20px 0);
          clear: both;
          a{
            color: #0093ff;
          }
        }
        input[type="submit"]{
          @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 400, $color: $whiteColor, $margin: 10px 0 0 0, $padding: 15px);
          width: 200px;
          line-height: normal;
          letter-spacing: .7px;
          background: #2f8cff;
          border: 1px solid #2670ca;
          border-radius: 3px;
          cursor: pointer;
        }
      }
      .MuiRadio-colorSecondary.Mui-checked{
        color: #3f51b5;
      }
    }
  }
  .features-wrapper{
    padding: 70px 40px;
    background-color: #ffffff;
    .heading-block{
        text-align: center;
        h2{
            @include elementStyles(24px, #7232d4, normal, normal, $ibmplexRegular, null, null);
        }
        h3{
            @include elementStyles(24px, #000000, normal, normal, $ibmplexRegular, null, null);
            margin-top: 5px;
            margin-bottom: 10px;
        }
    }
    .feature-content-block{
      padding: 0 30px;
        .list-inline{
            text-align: center;
            margin-bottom: 80px;
            li{
                display: inline-block;
                a{
                    background-color: #f7f7f7;
                    @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                    padding: 10px 40px;
                    display: inline-block;
                    width: 235px;
                    border: 1px solid #c2c2c2;
                    vertical-align: -webkit-baseline-middle;
                    &.tab-active{
                        color: #ffffff;
                        border: 1px solid #0a56a8;
                        background-image: linear-gradient(to right, #6538db, #0a56a8);
                        background-image: -webkit-linear-gradient(to right, #6538db, #0a56a8);
                        background-image: -moz-linear-gradient(to right, #6538db, #0a56a8);
                    }
                }
            }
        }
        .tab-content{
            .background-difference{
                padding:40px;
                margin: 0px 0 30px;
                background: #FBF9FE;
                &.last-background{
                    margin: 0px;
                }
              .download-links{
                background: #F4F0FA;
                border-radius: 10px;
                margin-top: 15px;
                margin-left: -15px;
                padding: 15px;
              }
            }
            img{
                max-width: 100%;
            }
            .flex-centered{
                padding-bottom: 40px;
            }
            h4{
                @include elementStyles(24px, #000000, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 10px;
            }
            p{
                @include elementStyles(16px, #4f6276, normal, normal, $ibmplexRegular, null, null);
              &.card{
                @include elementStyles(12px, #777777, normal, normal, $ibmplexRegular, null, null);
                border: none;

              }

            }
            ul{
              &.list-unstyled{
                padding-left: 35px;
              }
                margin-top: 30px;
                text-align: left;
                margin-bottom: 100px;
                li{
                    @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                    margin-bottom: 15px;
                    position: relative;
                    padding-left: 15px;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        background-color: #2893FF;
                        border-radius: 50%;
                        left: 0;
                        top: 6px;
                    }
                }
            }
            .wrapper{
              position: absolute;
              width: 100%;
              left: 50%;
              transform: translateX(-50%);
              bottom: 15px;
            }
            .signup{
              bottom: 30px;
            }
            .contact{
              bottom: 30px;
            }
            .download-links{
              background: #F4F0FA;
              border-radius: 10px;
              margin-top: 15px;
              margin-left: -15px;
              padding: 15px;
            }
        }
    }
  }
  .share-content-section{
    padding: 0 40px;
    .share-header{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: #ffffff;
      box-shadow: 0 2px 2px rgba(0,0,0,.2);
      padding: 5px;
      .lessor-info{
        float: right;
        li{
          img{
            max-width: 80px;
          }
        }
      }
    }
    .navigation-box{
      padding:5px 15px;
      margin-bottom: 20px;
      h4{
        @include elementStyles($size: 18px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
        span{
          float: right;
          cursor: pointer;

        }
      }
    }
    .shared-content-list{
      ul{
        li{
          margin-right: 20px;
          margin-bottom: 20px;
          .folder-card{
            background: #ffffff;
            box-shadow: 0 2px 2px rgba(0,0,0,.25);
            width: 170px;
            border-radius: 4px;
            position: relative;
            .folder-icon{
              height: 120px;
              background: #F0F0F0;
              position: relative;
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
              background-position: center;
              background-repeat: no-repeat;
              .check-box{
                position: absolute;
                top: 0px;
                left: 0px;
              }
              .folder-img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
              }
            }
            .folder-info{
              padding: 10px;
              background: #ffffff;
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
              position: relative;
              h5{
                @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
                margin-bottom: 3px;
                cursor: pointer;
                &:hover{
                  text-decoration: underline;
                }
              }
              p{
                @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.70));
              }
              .shared-info{
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                right: 15px;
                top: 10px;
                cursor: pointer;
                img{
                  width: 15px;
                }
              }
            }
            .context-menu-action{
              position: absolute;
              right: 10px;
              bottom: 0px;
              cursor: pointer;
            }
          }
        }
      }
    }

  }
}
@media only screen and (max-width: 600px){
  body{
    .auth-section{
      padding: 40px 0 0;
      min-height: 80vh;
      .auth-help-text{
        br{
          display: none;
        }
      }
      .auth-inner-blk{
          padding-top: 50px;
          padding-bottom: 50px;
          position: unset;
          top: auto;
          left: auto;
          transform: none;
        .auth-help-text{
          h1{
            font-size: 30px;
            margin-bottom: 15px;
          }
          p{
            font-size: 21px;
            margin-bottom: 15px;
          }
        }
        .auth-form-wrapper{
          padding: 15px;
          &.lessor-selection-from{
            margin-top: 0px;
          }
          .form-para{
            margin-bottom: 5px;
            br{
              display: none;
            }
          }
          .form-title{
            margin-bottom: 10px;
          }
          form{
            .text-center{
              margin-top: 10px;
            }
            .terms-and-condition{
              margin-bottom: 15px;
              br{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 601px) and (max-width: 767.98px) {
  body {
    .auth-section{
      .auth-inner-blk{
        padding-top: 80px;
        padding-bottom: 50px;
        position: unset;
        top: auto;
        left: auto;
        transform: none;
        .auth-help-text{
          h1{
            font-size: 32px;
            margin-bottom: 15px;
          }
          p{
            font-size: 22px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 960px) and (min-width: 768px){
  body {
    .auth-section{
      min-height:80vh;
      .auth-inner-blk{
        padding-top: 80px;
        padding-bottom: 50px;
        position: unset;
        top: auto;
        left: auto;
        transform: none;
        .auth-help-text{
          h1{
            font-size: 32px;
            margin-bottom: 15px;
          }
          p{
            font-size: 22px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1280px) and (min-width: 960px){

}
