$robotoFamily: 'Roboto', sans-serif;
$ibmplexLight : 'Conv_IBMPlexSans-Light';
$ibmplexRegular : 'Conv_IBMPlexSans-Regular';
$ibmplexMedium : 'Conv_IBMPlexSans-Medium';
$ibmplexSemiBold : 'Conv_IBMPlexSans-SemiBold';
$ibmplexBold : 'Conv_IBMPlexSans-Bold';
// Color variation
$whiteColor: #FFFFFF;
$blackColor: #000000;
$redColor: #FF0000;

// Font Size variation
$font10: 10px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font24: 24px;
$font32: 32px;
$font36: 36px;

// Element Styles
@mixin elementStyles($size: null, $color: null,  $lineHeight: null, $weight: null, $fontFamily: null, $margin: null, $padding: null){
  @if $size != null {
    font-size: $size;
  }
  @if $lineHeight != null {
    line-height: $lineHeight;
  }
  @if $weight != null {
    font-weight: $weight;
  }
  @if $color != null {
    color: $color;
  }
  @if $fontFamily != null {
    font-family: $fontFamily;
  }
  @if $margin != null {
    margin: $margin;
  }
  @if $padding != null {
    padding: $padding;
  }
}
